<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：开单菜单栏
	开始时间：2021-01-18
	开发人员：刘巍骏
	最后修改：2021-01-18
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billing_menuAll">
    <el-tabs>
      <el-tab-pane label="报价" name="0">
        <!-- <offerView></offerView> -->
      </el-tab-pane>
      <el-tab-pane label="下单" name="1">下单</el-tab-pane>
      <el-tab-pane label="采购" name="2">采购</el-tab-pane>
      <el-tab-pane label="生产计划" name="3">生产计划</el-tab-pane>
      <el-tab-pane label="生产中" name="4">生产中</el-tab-pane>
      <el-tab-pane label="品检" name="5">品检</el-tab-pane>
      <el-tab-pane label="入库" name="6">入库</el-tab-pane>
      <el-tab-pane label="发货" name="7">发货</el-tab-pane>
      <el-tab-pane label="运输" name="8">运输</el-tab-pane>
      <el-tab-pane label="收货" name="9">收货</el-tab-pane>
      <el-tab-pane label="完结" name="10">完结</el-tab-pane>
      <el-tab-pane label="售后" name="11">售后</el-tab-pane>
      <el-tab-pane label="日志" name="12">日志</el-tab-pane>
      <el-tab-pane label="统计" name="13">统计</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import store from '@/vuex/store'
  // 导入组件
  // import offerView from '@/components/billing/offer/offer_view.vue';//报价组件

  export default{
    name:'billingMenu',
    data(){
      return{

      }
    },
    mounted() {
      this.$store.state.routerLoading = false
    },
    components:{
      // offerView
    }
  }
</script>

<style>
  .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 20px !important;
  }
</style>
